
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    background-color: white;
    box-shadow: 0px 4px 25px -1px rgba(0, 0, 0, 0.15);
    width: 297px;
    padding: 70px 40px 30px;
    position: relative;
    margin-bottom: 20px;
    @media screen and (max-width: 1320px) {
        width: 270px;
        padding: 50px 30px 30px;
    }
    @media screen and (max-width: 1150px) {
        width: 225px;
        padding: 40px 20px 30px;
    }
}
.flexItems {
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
    & > div {
        @media screen and (max-width: 1000px) {
            flex: 0 48%;
            margin-bottom: 30px;
        }
        @media screen and (max-width: 500px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.itemTitle {
    color: #444b53;
    font-weight: 700;
    position: relative;
    @media screen and (max-width: 1150px) {
        font-size: 18px;
    }
    &::after {
        content: "";
        display: block;
        height: 1px;
        width: 200px;
        margin: 20px auto;
        background-color: #444b53;
        @media screen and (max-width: 1150px) {
            width: 160px;
        }
    }
}
.itemIcon {
    svg {
        display: inline-block;
        margin-bottom: 50px;
        @media screen and (max-width: 1150px) {
            width: 90px;
            height: auto;
            margin-bottom: 30px;
        }
    }
    // @media screen and (max-width: 1150px) {
    //     margin-bottom: 30px;
    // }
}
.feature {
    background: url("/assets/images/tick.svg") no-repeat left center;
    padding-left: 20px;
    color: #444b53;
    @media screen and (max-width: 1150px) {
        font-size: 14px;
    }
}
.num {
    color: #ea7969;
    position: absolute;
    left: 20px;
    top: 15px;
}
